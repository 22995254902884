@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body.sidebar-open::before {
  content: " ";
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: #000;
  top: 0;
  left: 0;
  opacity: 0.5;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
}

.chat-container {
  scroll-behavior: smooth;
}

/* MODAL STYLE */

.modal-id {
  color: gray;
  font-size: 0.8rem;
}
.modal-head {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
}
.modal-sub {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
.modal-form {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  gap: 0.3rem;
}
.modal-subs {
  font-size: 0.8rem;
}
.modal-btn {
  margin: 1rem 0;
}

.modal-flex {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}
.modal-flexs {
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 0;
}
.modal-flexed {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.modal-com-name {
  color: #d50012;
}
.modal-user-name {
  font-size: 1.1rem;
}
.modal-other-text {
  font-size: 0.9rem;
}
.modal-margin {
  margin: 1rem 0;
}

.modal-green {
  font-size: 1.5rem;
  font-weight: 400;
  color: #2cbf16;
}
.modal-red {
  font-size: 1.5rem;
  font-weight: 400;
  color: #d50012;
}
.modal-redprice {
  font-size: 1.1rem;
  font-weight: 700;
  color: #d50012;
  margin-bottom: 1rem;
}

.service-head .service-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.form-control{
  margin-bottom: 20px;
}

@media only screen and (max-width: 667px) {
  .react-multiple-carousel__arrow {
    background-color: rgb(255 255 255 / 0%) !important;
  }

  .react-multiple-carousel__arrow::before {
    color: black !important;
  }
}
